@import 'bootstrap';

.mt-150{
    margin-top: 150px !important;
}

.mt-200{
    margin-top: 200px !important;
}

.mb-150{
    margin-bottom: 150px !important;
}